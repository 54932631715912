import React from "react";
import loadable from "@loadable/component";
import Layout from "../../../components/Layout/layout";
import SEO from "../../../components/seo";
import BHero from "../../../components/Hero/b-hero";
import {
  oilChangeHero,
  oilChangeSubHero,
  oilChangeWhiteGlove,
  oilChangeLargeFeatureA,
  oilChangeTrusted,
  oilChangeMultiSection,
  oilChangeCTA,
  oilChangeVideo,
  // oilChangeTestimonials,
  oilChangeArticles,
  oilChangeCar,
} from "../../../data/subverticals/automotive/oil-change-data";
import {
  heroSuccess,
  NorthEastAutoSlides,
} from "../../../data/success-stories-data";
// import NoVisualsCustom from "../../../components/NoVisuals/NoVisualsCustom";
// import WhiteGlove from "../../../components/WhiteGlove/WhiteGlove";
// import LargeFeatures from "../../../components/LargeFeatures/LargeFeatures";
// import TrustedNumbers from "../../../components/TrustedNumbers/TrustedNumbers";
// import LargeCta from "../../../components/CTAs/LargeCta";
// import SuccessStories from "../../../components/SuccessStories/SuccessStories";
// import VideoSection from "../../../components/VideoSection/VideoSection";
// import BusinessTypes from "../../../components/BusinessTypes/BusinessTypes";
// import Articles from "../../../components/Articles/Articles";
// import Articles from '../../components/Articles/Articles';

import heroBg from "../../../images/hero-images/auto-parts-hero.png";

const NoVisualsCustom = loadable(() =>
  import("../../../components/NoVisuals/NoVisualsCustom")
);
const WhiteGlove = loadable(() =>
  import("../../../components/WhiteGlove/WhiteGlove")
);
const LargeFeatures = loadable(() =>
  import("../../../components/LargeFeatures/LargeFeatures")
);
const TrustedNumbers = loadable(() =>
  import("../../../components/TrustedNumbers/TrustedNumbers")
);
const LargeCta = loadable(() => import("../../../components/CTAs/LargeCta"));
const SuccessStories = loadable(() =>
  import("../../../components/SuccessStories/SuccessStories")
);
const VideoSection = loadable(() =>
  import("../../../components/VideoSection/VideoSection")
);
const BusinessTypes = loadable(() =>
  import("../../../components/BusinessTypes/BusinessTypes")
);
const Articles = loadable(() =>
  import("../../../components/Articles/Articles")
);

const whiteGloveImport = "auto-repair-wg.png";

const OilChange = () => {
  return (
    <Layout>
      <SEO
        title="Oil Change Point of Sale (POS) Software | SpotOn"
        description="SpotOn's oil change shop POS software can help attract new customers, manage service appointments, improve customer loyalty, and get paid faster. Request a demo."
        image={`https://spoton.com/${heroBg}`}
        cannonical="https://www.spoton.com/automotive/oil-change-shop/"
      />
      <BHero sectionData={oilChangeHero} heroBg="oil-change-hero.png" />
      <NoVisualsCustom sectionData={oilChangeSubHero} complex />
      <WhiteGlove
        sectionData={oilChangeWhiteGlove}
        whiteGloveBg={whiteGloveImport}
      />
      <LargeFeatures
        sectionData={oilChangeLargeFeatureA}
        style={{ marginTop: "128px" }}
      />
      <TrustedNumbers title="By the numbers" numbersArray={oilChangeTrusted} />
      <LargeFeatures
        sectionData={oilChangeMultiSection}
        style={{ marginTop: "128px !important" }}
      />
      <LargeCta sectionData={oilChangeCTA} />
      <SuccessStories sectionData={heroSuccess} slides={NorthEastAutoSlides} />
      <VideoSection sectionData={oilChangeVideo} />
      {/* <TestmonialReviews sectionData={oilChangeTestimonials} /> */}
      <Articles
        sectionData={oilChangeArticles}
        blogFilters={{
          limit: 3,
          include: "tags,authors",
          filter: "tag:auto",
        }}
        cornerStone={false}
        style={{ marginTop: 60 }}
      />
      <BusinessTypes sectionData={oilChangeCar} />
      {/* <Faqs sectionData={oilChangeFaqs} /> */}
    </Layout>
  );
};

export default OilChange;
