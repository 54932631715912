// eslint-disable-next-line import/prefer-default-export
export const oilChangeHero = {
  title: "Oil change POS system",
  subtext:
    "Increase sales and save time with one integrated system to attract new customers, manage service appointments, improve customer loyalty, and get paid faster.",
  PrimaryCta: {
    ctaTitle: "Learn more",
    ctaTarget: "/automotive/oil-change-shop/demo",
  },
};
export const oilChangeSubHero = {
  title: "Simple tech to improve your bottom line",
  subtext:
    "Speed and performance are a must for lube and oil shops. SpotOn simplifies the back-office work and saves you time, so you can get back under the hood.",
  list: [
    "Attract new customers with a stronger online presence",
    "Improve your customer experience",
    "Manage appointments and team schedules",
    "Improve customer retention and grow your revenue",
    "Offer easier ways to collect payments",
  ],
  PrimaryCta: {
    ctaTitle: "Learn more",
    ctaTarget: "/automotive/oil-change-shop/demo",
  },
};

export const oilChangeWhiteGlove = {
  mainTitle: "We’re here for you",
  title: "Service you can trust",
  content:
    "We understand how busy your business can get, and we’re here to help. Our team will work closely with you to evaluate your business needs and create a solution that makes life easier for you and your customers. We’ll train you and your team on how to make the most of your new tools. And if you need more assistance, you can count on our local service and 24/7 support.",
  ctaInfoPrimary: {
    ctaTitle: "Learn more",
    ctaTarget: "/automotive/oil-change-shop/demo",
  },
};

export const oilChangeLargeFeatureA = {
  featureBlocks: [
    {
      blockTitle: "Get the well-oiled machine you deserve",
      blockSubTitle:
        "You don’t have time to waste on a half-dozen different apps and computer systems. Simplify with SpotOn. Our cloud-based tools are thoughtfully designed to work together, with a single login, whether you’re in the shop, in the back office, or keeping an eye on your business while on the road. And our pricing is fair, so you pay less while earning more.",
      blockList: [
        "Transparent pricing",
        "Next-day funding",
        "No hidden fees",
        "No long-term commitment",
      ],
      blockImg: "oil-a.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/automotive/oil-change-shop/demo",
      },
    },
  ],
};

export const oilChangeTrusted = [
  {
    title: "91%",
    subtext:
      "Of Millennials trust online reviews as much as friends and family",
  },
  {
    title: "88%",
    subtext: "Of people search online before visiting a business",
  },
  {
    title: "50%",
    subtext: "Increase in purchase of new services with loyal customers",
  },
];

export const oilChangeMultiSection = {
  featureBlocks: [
    {
      blockTitle: "Boost sales with online appointments",
      blockSubTitle:
        "Easily manage appointments and team member schedules while allowing your customers to book at any time, right from your website or your Facebook page.",
      blockList: [
        "Online appointment booking",
        "Interactive calendar for drop-offs & appointments",
        "Text and email reminders to reduce no-shows",
        "Employee shift management",
        "Mailing list builder",
      ],
      blockImg: "oil-b.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/automotive/oil-change-shop/demo",
      },
    },
    {
      blockTitle: "Take payments and more",
      blockSubTitle:
        "SpotOn Terminal makes it easy to cut the wires, connect with customers, and get paid. Take payments wherever you want, drive repeat visits with built-in digital loyalty, and use the customer data to make smarter business decisions.",
      blockList: [
        "Apple Pay, Google Pay, and NFC enabled",
        "Wi-Fi and 4G connectivity",
        "Customer data capture",
        "Built-in receipt printer and scanner",
        "Cash discount and surcharge ready",
      ],
      blockImg: "oil-c.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/automotive/oil-change-shop/demo",
      },
    },
    {
      blockTitle: "Loyalty rewards for repeat customers",
      blockSubTitle:
        "Boost revenue, grow your customer base, and encourage repeat visits by offering custom loyalty rewards with SpotOn Loyalty. It’s easy to set up and easy to manage.",
      blockList: [
        "Create custom rewards",
        "Enroll customers during checkout",
        "Increase sales with each check-in & redemption",
      ],
      blockImg: "oil-d.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/automotive/oil-change-shop/demo",
      },
    },
    {
      blockTitle: "Stand out from the competition",
      blockSubTitle:
        "Don’t sink time into building your own website. Our specialists will build a professional website for you at a do-it-yourself price, so you show up higher in Google searches and attract more customers.",
      blockList: [
        "Custom website design",
        "Online appointment booking",
        "Lifetime support",
        "Custom website name",
        "Self-service dashboard",
      ],
      blockImg: "oil-e.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/automotive/oil-change-shop/demo",
      },
    },
    {
      blockTitle: "Integrated tools to connect with your customers",
      blockSubTitle:
        "Run and grow your business from a single dashboard—online or right from your phone. In addition to managing your payments, loyalty rewards, and appointments, you can:",
      blockList: [
        "Send marketing emails",
        "Create Facebook campaigns",
        "Send digital coupons",
        "Monitor your online reputation on Yelp, Google, and TripAdvisor",
        "Send invoices and run recurring payments",
      ],
      blockImg: "oil-f.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/automotive/oil-change-shop/demo",
      },
    },
  ],
};

export const oilChangeCTA = {
  title: "Book your consultation.",
  ctaImg: "auto-repair-cta.png",
  ctaInfo: {
    ctaTitle: "Learn more",
    ctaTarget: "/automotive/oil-change-shop/demo",
  },
};

export const oilChangeVideo = {
  title: "The road to success",
  videoBtn: "Watch video",
  posterImg: "oil-thumb.png",
  posterWrapperClass: "bg-blue",
  videoUrlId: "4pIhYB_LvJM",
};

export const oilChangeTestimonials = {
  title: "What other business owners are saying",
  reviews: [
    {
      quote:
        "From the initial consultation, to ordering, to implementation and integration, the entire process of on-boarding the SpotOn POS System has been seamless and has exceeded our expectations.",
      reviewDate: "Mar 9, 2021",
      reviewer: "Pete M.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn continues to be an invaluable technology partner that listens well and proactively presents innovative solutions to drive our business forward.",
      reviewDate: "Feb 9, 2021",
      reviewer: "Ben P.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn has given me the data access I need to manage my business from a macro as well as micro perspective. The software is easy to access and utilize.",
      reviewDate: "Dec 5, 2020",
      reviewer: "Pamela H.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "Every issue and question has been answered or addressed within hours, if not minutes.I can't say enough good about this company and software.",
      reviewDate: "Feb 24, 2021",
      reviewer: "Brandy R.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "From back office, digital marketing, built our website to take orders off of. The programming is great to allow you to do more tasks with better trained staff.",
      reviewDate: "Dec 1, 2020",
      reviewer: "Marcus O.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
  ],
};

export const oilChangeArticles = {
  title: "Expert articles & advice on growing your oil change business",
  bgText: "Articles.",
};

export const oilChangeFaqs = {
  title: "Frequently Asked Questions",
  ctaInfo: {
    ctaTitle: "View more",
    ctaTarget: "/page-2",
  },
  list: [
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
  ],
};

export const oilChangeCar = {
  title: "Run a different type of automotive business",
  subtext: "Oil change shops are just one of our specialties.",
  punctuation: "?",
  slides: [
    {
      bg: "auto-repair.png",
      cardTitle: "Auto repair",
      cardContent: "Drive repeat visits and more",
      icon: "auto-repair.png",
      linkTo: "/automotive/auto-repair",
    },
    {
      bg: "auto-parts.png",
      cardTitle: "Auto parts",
      cardContent: "Tools to grow your business\n",
      icon: "auto-parts.png",
      linkTo: "/automotive/auto-parts",
    },
    {
      bg: "oil-change.png",
      cardTitle: "Oil Change Shop",
      cardContent:
        "Find more customers, book more appointments, keep them coming back",
      icon: "oil-change.png",
      linkTo: "/automotive/oil-change-shop",
    },
    {
      bg: "tire-center.png",
      cardTitle: "Tire center",
      cardContent: "Tools to grow your business\n",
      icon: "tire-shop.png",
      linkTo: "/automotive/tire-center",
    },
    {
      bg: "car-sales.png",
      cardTitle: "Auto sales",
      cardContent: "Tools to grow your business\n",
      icon: "auto-sales.png",
      linkTo: "/automotive/auto-sales",
    },
  ],
};
